@use "@angular/cdk/overlay-prebuilt";
@use "definitions" as *;

body {
    overflow-x: auto;
}

// Overwriting default a tag behavior
a.codman-link {
    text-decoration: none;
    color: lg-color(--gray-color-80);

    &:hover {
        color: lg-color(--gray-color-80);
    }
}
